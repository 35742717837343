function convertDecimalToBRLCurrency(value, decimals = 2) {
  return value.toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  })
}

function formatCurrency(str, decimals = 2) {
  const decimalStr = parseFloat(str)

  return convertDecimalToBRLCurrency(decimalStr, decimals)
}

function unformatCurrency(str, decimals) {
  if (!str) {
    return ''
  }

  str = str.replace(/[^0-9]/g, '')

  str = str.padStart(3, '0')

  const index = str.length - decimals

  const strWithDecimal = str.slice(0, index) + '.' + str.slice(index)

  return parseFloat(strWithDecimal)
}

const VueMoney = {
  install(Vue) {
    Vue.mixin({
      filters: {
        unformatCurrency(value) {
          if (!value) return ''
          return unformatCurrency(value)
        },
        formatCurrency(value, decimals = 3) {
          if (!value) return ''
          return formatCurrency(value, decimals)
        },
      },
      methods: {
        formatCurrency,
        unformatCurrency,
      },
    })
  },
}

export default VueMoney
