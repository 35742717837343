import Vue from 'vue'
import VueCookies from 'vue-cookies'
import axios from 'axios'

Vue.use(VueCookies)

/*
 ** Criando a instância do axios
 */
const api = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
})

/*
 ** Definindo um token de segurança para requisições
 ** feitas por usuários logados para endpoints que esperam um token
 */
api.interceptors.request.use(
  (config) => {
    if (Vue.$cookies.get('token')) {
      config.headers.common.Authorization = `Bearer ${Vue.$cookies.get(
        'token'
      )}`
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

export default api
