<template>
  <v-app :style="{ background: $vuetify.theme.themes.light.background }">
    <Modal />

    <AppBar />

    <Drawer />

    <v-main>
      <v-container fluid>
        <v-card min-height="85vh">
          <transition name="fade">
            <router-view />
          </transition>
        </v-card>
      </v-container>
    </v-main>

    <ContextMenu />
  </v-app>
</template>

<script>
export default {
  name: 'Layout',
  components: {
    AppBar: () => import('./AppBar'),
    Drawer: () => import('./Drawer.vue'),
    ContextMenu: () => import('@/components/ContextMenu'),
    Modal: () => import('@/components/modal/Modal'),
  },
}
</script>

<style lang="scss">
.fade-leave-from,
.fade-enter-to {
  opacity: 1;
}

.fade-leave,
.fade-enter {
  transition: all 0.3s ease-in;
}

.fade-leave-to,
.fade-enter-from {
  opacity: 0;
}
</style>
