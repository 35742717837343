import { BackendClient } from '../integrations/BackendClient'
import { BaseService } from './BaseService'

export class ProductService extends BaseService {
  async findProductByCode(code) {
    console.log(code)
    const response = await BackendClient.post(
      'products/filter/budget/reference',
      {
        product_reference: code,
      }
    )

    return response.data.data
  }
}
